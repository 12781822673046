import { Injectable, Signal, WritableSignal, computed, effect, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  totalSteps: WritableSignal<number> = signal<number>(6);
  currentStep: WritableSignal<number> = signal<number>(1);
  #fraction: Signal<number> = computed<number>( () => 100 / this.totalSteps());
  progress: Signal<number> = computed<number>( () => this.currentStep() * this.#fraction());

  constructor() {
    effect(() => {
      //* Avoid overvalue
      if( this.currentStep() > this.totalSteps() ) this.currentStep.set( this.totalSteps() );
      //* Avoid undervalue
      if( this.currentStep() < 1 ) this.currentStep.set( 1 );
    }, { allowSignalWrites: true });
  }

}
